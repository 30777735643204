function Hero() {
  return (
    <div
      id="hero"
      className="flex flex-col w-full justify-center items-center  text-center p-24"
    >
      <div className="flex items-center justify-evenly w-full">
        <img
          className="hidden lg:block w-[360px] h-[240px] object-fill"
          src={'/images/nh3_middle.jpeg'}
          alt="NH3"
        />
        <div className="max-w-xl space-y-6">
          <div className="text-2xl font-bold">
          Our focus is on the development of precise and user-friendly scientific and industrial instruments.
          </div>
          <div className="">
           We invest in research and product development, and our specialists and engineers consistently remain at the forefront of advancements.
          </div>
          <div className="flex space-x-5 justify-center whitespace-nowrap">
            <button className="bg-[#D08247] rounded-md px-4 py-2 text-white font-bold">
              <a href="#form" className="text-white ">
                Email us
              </a>
            </button>
            <button className="border border-[#D08247] px-4 py-2 rounded-md">
              <a href="/products" className="text-black ">
                Go to catalog
              </a>
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="hidden lg:block w-[240px] h-[200px]"
            src={'/images/borei_perenos_1.png'}
            alt="NH3"
          />
          <img
            className="hidden lg:block w-[320px] h-[160px] object-fill"
            src={'/images/borey-1.png'}
            alt="NH3"
          />
        </div>
        
      </div>
    </div>
  );
}

export default Hero;
