import Navbar from "./Navbar";
import Form from "./Form";
import Hero from "./Hero";
import Services from "./Services";
import Footer from "./Footer";
import Gallery from "./Gallery";
import { Toaster } from 'react-hot-toast';

function Landing() {
  return (
    <>
      <Toaster />
      <Navbar />
      <Hero />
      <Services />
      <Gallery />
      <Form />
      <Footer />
    </>
  );
}

export default Landing;
