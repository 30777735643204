import { WiDayCloudyWindy } from "react-icons/wi";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { GiBuyCard } from "react-icons/gi";
import { FiDownload } from "react-icons/fi";

function Services() {
  return (
    <div
      id="services"
      className="bg-[#91AD58] flex flex-col items-center justify-evenly space-y-10 space-x-5 p-12 text-center text-white"
    >
      <div className=" text-3xl font-bold">Our services</div>
      <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 items-center md:space-x-16">
        <div className="flex flex-col max-w-xs justify-center items-center space-y-3">
          <WiDayCloudyWindy className="text-white w-16 h-16" />
          <p>Metrological certification of equipment</p>
        </div>
        <div className="flex flex-col max-w-xs justify-center items-center space-y-3">
          <HiOutlineWrenchScrewdriver className="text-white w-14 h-14" />
          <p>
          Development and production of analytical instruments and systems monitoring
          </p>
        </div>
        <div className="flex flex-col max-w-xs justify-center items-center space-y-3">
          <GiBuyCard className="text-white w-16 h-16" />
          <p>Sale of analytical instruments and components</p>
        </div>
      </div>
      <div className="max-w-4xl pt-10">
        We cooperate with many standardization institutes and
        government agencies, as well as well-known companies
        producing analytical equipment in the field of system development and
        individual devices.
      </div>
      <div className="flex w-fit justify-center mx-auto rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
          <FiDownload size={24}/>
          <a href='/documents/products.pdf'>
          Download brochure
          </a>
        </div>
    </div>
  );
}
export default Services;
