import { Route, Routes } from "react-router-dom";
import Landing from "./components/Landing";
import Products from "./components/Products";
import SingleProduct from "./components/SingleProduct";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:productId" element={<SingleProduct />} />
    </Routes>
  );
}

export default App;
