const products = [
  {
    id: "1",
    name: 'Automatic suspended particulate analyzers "Borey" (stationary)',
    short_description: "description1",
    description:
      "The automatic suspended particulate analyzers 'Borey' are designed for measuring the mass concentration and density of suspended (aerosol) particles (total suspended particulate matter TSP, PM-10, PM-2.5, and PM-1.0 fractions). The field of application includes atmospheric air monitoring, sanitary-hygienic and technological environmental control, industrial safety assurance, cleanroom environment monitoring at various sites, and ensuring safe working conditions.",
    document_path: "/documents/borey.docx",
    image: [
      {
        url: "/images/borey-1.png",
      },
      {
        url: "/images/borey-2.jpeg",
      },
    ],
    category: "air",
  },
  {
    id: "2",
    name: "Topas — stationary particulate matter analyzer",
    short_description: "description1",
    description:
      "Topas is a stationary analyzer for long-term measurement at a single point. It is possible to combine several devices into a network via GSM or radio modems. Topas can measure concentrations of particulate fractions TSP, PM10, PM2.5, and PM1 simultaneously. An integrated reference filter is used to check and calibrate the analyzers using the gravimetric method. The device stores particle concentrations in its internal memory. Additionally, the Topas dust monitor is equipped with sensors for wind speed and direction, temperature, humidity, precipitation, as well as connectors for connecting a noise meter or gas analyzer. Applications include use in laboratories and production facilities across various industries for atmospheric and workplace air quality control. Features include simultaneous measurement of particle concentration fractions TSP, PM10, PM2.5, and PM1.",
    document_path: "/documents/topas.pdf",
    image: [
      {
        url: "/images/topas-1.jpeg",
      },
      {
        url: "/images/topas-2.jpeg",
      },
    ],
    category: "air",
  },
  {
    id: "3",
    name: "Environmental monitor DustMate",
    short_description: "description1",
    description:
      "The DustMate environmental monitor is a portable detector for dust and smoke that is ideal for short-term sampling. Versatile and practical, this portable device perfectly suits short-term testing of air quality in buildings, workplaces, and roadside environments. Thanks to its very fast response time, it provides accurate readings and can pinpoint sources of dust and fumes in the air even at very low concentrations. An efficient device for monitoring air quality, the DustMate measures TSP, PM10, PM2.5, and PM1 simultaneously in real-time. It also continuously displays the particle concentration.",
    document_path: "/documents/dustmate.docx",
    image: [
      {
        url: "/images/dustmate.jpg",
      },
    ],
    category: "air",
  },
  {
    id: "4",
    name: "Automatic sampling system PNS T-DM",
    short_description: "description1",
    description:
      "A reference sampling system for continuous monitoring of solid particles (PM10, PM2.5 or PM1) with automatic filter change. \n PNS T-DM combines a low/medium volume sampler (LVS or MVS) with a replaceable filter housed in stainless steel. It collects fine particles on filters for sampling in accordance with EN 12341:2014 (PM10 and PM2.5). To this end, a vacuum pump draws ambient air through the system which fractionates airborne particles at the sample inlet. Then the air containing the desired fine particle fraction passes through a filter where particles are collected and become available for subsequent gravimetric estimation or analysis. Automatic filter change driven by a Geneva mechanism with two parallel filter cartridges allows for a consistent series of up to 18 or 24 sampling cycles. Flow rate is measured by diaphragm and electronically regulated to an accuracy of ≤ 2%",
    document_path: "/documents/pns18.docx",
    image: [
      {
        url: "/images/pns18.png",
      },
    ],
    category: "air",
  },
  {
    id: "5",
    name: "Berkut CH2O Gas Analyzers",
    short_description: "description1",
    description:
      "The Berkut CH2O gas analyzers are designed to measure formaldehyde (CH2O) content in atmospheric air and workplace air.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/ch2o_front.jpeg",
      },
      {
        url: "/images/ch2o_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "6",
    name: "Berkut CO2 Gas Analyzers",
    short_description: "description1",
    description:
      "The Berkut CO2 gas analyzer is designed to measure the volume fraction of carbon dioxide in atmospheric air and workplace air. The analyzer is an automatic instrument with continuous operation.",
    document_path: "/documents/berkut_02.docx",
    image: [
      {
        url: "/images/co2_front.jpeg",
      },
      {
        url: "/images/co2_back.jpeg",
      },
    ],
    category: "gaz",
  },
  {
    id: "7",
    name: "Berkut H2S Gas Analyzers ",
    short_description: "description1",
    description:
      "The Berkut H2S gas analyzer is designed to measure the mass concentration or volume fraction of hydrogen sulfide in air environments. The gas analyzer is a stationary, automatic, indicating instrument with continuous operation.",
    document_path: "/documents/berkut_02.docx",
     image:[
       {url:"/images/h2s_front.jpeg",},
       {url:"/images/h2s_back.jpeg",},
     ],
     category:"gaz", 
   },{
     id:"8", 
     name:"Berkut NH3 Ammonia Gas Analyzer", 
     short_description:"description1", 
     description:
       "The Berkut NH3 ammonia gas analyzer can quickly determine the mass or volume fraction of ammonia in atmospheric air and workplace air.", 
     document_path:"/documents/berkut_02.docx", 
     image:[
       {url:"/images/nh3_front.jpeg",},
       {url:"/images/nh3_middle.jpeg",},
       {url:"/images/nh3_back.jpeg",},
     ],
     category:"gaz", 
   },{
     id:"9", 
     name:"Automatic Indicating Device Berkut NO ", 
     short_description:"description1", 
     description:
       "An automatic indicating device, Berkut NO with continuous operation, intended for measuring mass concentration and volume fraction of nitric oxide in atmospheric air and workplace air.", 
     document_path:"/documents/berkut_02.docx", 
     image:[
       {url:"/images/no_front.jpeg",},
       {url:"/images/no_back.jpeg",},
     ],
     category:"gaz", 
   },{
     id:"10", 
     name:'Automatic suspended particulate analyzers "Borey" (portable)', 
     short_description:"description1", 
     description:
       "The automatic suspended particulate analyzers 'Borey' are dedicated to measuring mass concentration and density of suspended (aerosol) particles (total suspended particulate matter TSP, fractions PM-10, PM-2.5, PM-1.0). Field of application includes atmospheric air monitoring; sanitary-hygienic and technological environmental control; industrial safety provision; cleanroom environment monitoring at different sites; ensuring safe work conditions.", 
     document_path:"/documents/borey.docx", 
     image:[
       {url:"/images/borei_perenos_1.png",},
       {url:"/images/borei_perenos_2.jpeg",},
     ],
     category:"air", 
   },{
     id:"11", 
     name:'Automatic Sampling System PNS DMC', 
     short_description:"description1", 
     description:
       "Automatic sampling system for continuous monitoring of solid particles (PM10, PM2.5 or PM1) with replaceable dual cartridge filter system. The PNS DMC sampling system is based on low / medium volume sampling technology (LVS or MVS) featuring automatic filter changes. Compact design engineered for installation into a standard 19-inch rack.", 
     document_path:"/documents/dmc.pptx", 
     image:[
       {url:"/images/pmc_dmc.jpeg",}
     ],
     category:"air", 
   },{
     id:"12", 
     name:'Mobile Dust Monitoring Device MDM 2', 
     short_description:"description1", 
     description:
       "A mobile measuring device with scattered light sensor for real-time monitoring of PM 2.5 concentrationCompact and lightweight handheld measuring device MDM-2 (mobile dust monitor) measures current PM2.5 concentration on-site in real-time using photometric method employing scattered light (nephelometry). A graphical trend indicator displays variations in PM2.5 concentration.", 
      document_path:"/documents/mdm.pptx", 
      image:[
{url:"/images/mdm_1.jpeg"},
{url:"/images/mdm_2.jpeg"}
],
category:"air"
}
];

export default products;