import React, { useState } from "react";
import { Transition } from "@headlessui/react";

// import logo from "../assets/buip_logo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <nav className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-20">
            <div className="flex justify-between items-center w-full">
              <a href="/">
                <img
                  className="h-16 md:h-20"
                  src="/images/buip_logo.png"
                  alt="Logo"
                />
              </a>
              <div className="hidden md:block">
                <div className="flex items-baseline space-x-4">
                  <a
                    href="/#hero"
                    className=" hover:bg-[#91AD58] text-black hover:text-white px-3 py-2 rounded-md text-md font-medium"
                  >
                    About us
                  </a>
                  <a
                    href="/#services"
                    className="text-black hover:bg-[#91AD58] hover:text-white px-3 py-2 rounded-md text-md font-medium"
                  >
                    Services
                  </a>
                  <a
                    href="/products"
                    className="text-black hover:bg-[#91AD58] hover:text-white px-3 py-2 rounded-md text-md font-medium"
                  >
                    Catalog
                  </a>
                  <a
                    href="/#form"
                    className="text-black hover:bg-[#91AD58] hover:text-white px-3 py-2 rounded-md text-md font-medium"
                  >
                    Email us
                  </a>
                  <a
                    href="/#contacts"
                    className="text-black hover:bg-[#91AD58] hover:text-white px-3 py-2 rounded-md text-md font-medium"
                  >
                    Contacts
                  </a>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-[#91AD58] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#C0D086]"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <a
                  href="/#hero"
                  className="text-black hover:bg-[#91AD58] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Abous us
                </a>

                <a
                  href="/#services"
                  className="text-black hover:bg-[#91AD58] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Services
                </a>

                <a
                  href="/products"
                  className="text-black hover:bg-[#91AD58] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Catalog
                </a>

                <a
                  href="/#form"
                  className="text-black hover:bg-[#91AD58] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Email us
                </a>

                <a
                  href="/#contacts"
                  className="text-black hover:bg-[#91AD58] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contacts
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Navbar;
